@import "../../../assets/styles/variables";
:global {
  .cad-upload {
    .ant-upload-drag .ant-upload {
      padding: 0;
    }
    .ant-upload-drag .ant-upload-drag-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 0.5rem;
    }
    .ant-upload-drag {
      border-width: 0.2rem;
      &::after {
        transform: 0.3s all ease-in;
      }
    }
    .ant-upload-drag-hover {
      .ant-upload-drag-container * {
        pointer-events: none !important;
      }
      &::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: #00000022;
        pointer-events: none;
      }
    }
    .ant-upload-drag-icon {
      img {
        width: 12vw;
        pointer-events: none;
      }
    }
  }
  .cal-option {
    width: 300px;
    text-align: center;
    cursor: pointer;
    border-width: 2px;
    box-shadow: 0px 0.5rem 1rem rgba(179, 169, 148, 0.3);
    transition: 0.3s all ease-in-out;
    // border-radius: 0 0 8px 8px;
    // .anticon {
    //   opacity: 0;
    //   position: absolute;
    //   top: 8px;
    //   right: 8px;
    //   color: #4096ff;
    //   transition: 0.3s all ease-in-out;
    // }

    &.selected {
      border-color: var(--bs-primary);
      background-color: rgba(var(--bs-primary-rgb), 0.2);

      .anticon {
        opacity: 1;
      }
    }
  }

  .push-hold-button {
    border: none;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    transition: transform 0.2s, box-shadow 0.2s;
  }

  .push-hold-button:active,
  .push-hold-button:focus {
    transform: translateY(2px);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
  }
  .pushButton:active {
    transform: translateY(4px);
  }

  .custom-dxf-btn-group {
    .btn-custom-dxf {
      box-shadow: 0px 0.5rem 1rem rgba(179, 169, 148, 0.3);
      border: 1px solid black;
      background-color: #FFF;
      padding: 1rem 1.25rem;
      color: rgba(0, 0, 0, 0.88) !important;
      &.selected {
        border-color: var(--bs-primary);
        background-color: rgba(var(--bs-primary-rgb), 0.2);
        cursor: not-allowed;
        .anticon {
          opacity: 1;
        }
      }

      &.not-selected {
        border-color: #fff1f0;
        // background-color: #eee;
        cursor: pointer;
        .anticon {
          opacity: 1;
        }
      }
    }
  }

  .ant-collapse .ant-collapse-content > .ant-collapse-content-box {
    padding: 0;
  }
  .ant-collapse {
    margin-bottom: 16px;
    background-color: #fff;
  }

  .panel-part {
    background: #fffdf0;

    &.calculated {
      background: #f6ffed;
    }
    &.error {
      background: #fff1f0;
    }
    .footer-part {
      background-color: rgba(0, 0, 0, 0.02);
      padding: 12px 16px;
    }
    .ant-collapse {
      margin-bottom: 0;
      &.ant-collapse-ghost {
        .ant-collapse-header {
          padding-left: 0;
        }
      }
    }
  }

  .selectMaterial {
    width: 66vw !important;
    @media #{$tabletPortrait} {
      width: 100% !important;
    }
    .ant-modal-content {
      padding: 0;
      // max-height: 78vh;
      overflow: hidden;
    }
    .wrapper-materials {
      display: flex;
      height: 80vh;
      align-items: stretch;
      // height: 70vh;
      width: 100%;
      margin: 0;
      .ant-btn {
        &.ant-btn-primary:not(:disabled) {
          background: var(--selected-bg, var(--bs-button-hover-bg, var(--bs-primary)));
          color: var(--selected-color, var(--bs-button-color));
          border-color: var(--selected-bg, var(--bs-primary));
        }
      }
    }
    .wrapper-filters {
      // width: calc(100% - 2.5rem);
      padding: 1rem 3.5rem 1rem 1rem;
      display: flex;
      align-items: center;
      gap: 1rem;
      .ant-btn {
        height: calc(100% - 2px);
        min-width: unset;
      }
      .ant-select-single .ant-select-selector {
        height: calc(100% - 2px);
      }
      .ant-select {
        display: flex;
        align-items: center;
      }
    }
    .group {
      position: relative;
      display: flex;
      flex-direction: column;
      background: #f4f4f4;
      // max-height: 78vh;
      height: 100%;
      padding: 0.8rem 0;
      z-index: 1;
      .group-menu {
        padding: 0.8rem 0;
        max-height: 100%;
        overflow-y: auto;
      }
    }
    .filter-group {
      > button {
        flex: 1 1 auto
      }
    }
    .filter-thickness {
      > button {
        // flex: 1 1 auto;
        flex-basis: calc(25% - 0.4rem);
      }
    }
    .materials {
      display: flex;
      flex-direction: column;
      height: 100%;
    }
    .material-list {
      height: calc(100% - 4.5rem);
      overflow-y: auto;
      padding: 1rem;
      margin: 0;
    }
    .group-item {
      margin-bottom: 16px;
    }
    .material-item {
      width: 100%;
      margin: 0 0 8px 0;
      border: 1px solid #eaeaea;
      cursor: pointer;
      transition: all 0.2s ease-in-out;
      &:hover {
        background: var(--bs-button-hover-bg, var(--bs-primary));
        color: var(--bs-button-color);
      }
      &.active {
        background: var(--selected-bg, rgba(var(--bs-primary-rgb), 1));
        color: var(--bs-button-color);
      }
      &.no-match {
        opacity: 0.4;
        cursor: not-allowed;
        background-color: #f1f1f1;
        &:hover {
          background-color: #f1f1f1;
        }
      }

      div {
        padding: 0.5rem 1rem;
        // border-right: 1px solid #eaeaea;
        &:nth-last-child(1) {
          border: none;
          // color: #0378e355;
          b {
            // color: #0378e3;
          }
        }
      }

      .btn-favourite {
        opacity: 0.7;
        transition: transform 0.2s ease-in-out;
        &:hover{
          transform: scale(1.3);
        }
      }

      .text-symbol {
        span {
          opacity: 0.6;
        }
      }
    }
  }

  .calculation-done {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 50vh;
  }

  .form-quantity {
    .ant-input-number-group {
      .ant-input-number-group-addon {
        padding: 0;
        background-color: #f1f1f1;
      }
    }
    .ant-input-number-compact-item {
      height: 100%;
    }
  }

  .summary-price {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    hr {
      margin: 0.5rem 0;
    }

    > div {
      align-items: center;
      label {
        &:first-child {
          color: #6b624c;
        }
        &:last-child {
          color: #4d4637;
          font-weight: 600;
        }
      }

      &.shipping-cost {
        label {
          &:first-child {
            color: #f7766c;
          }
          &:last-child {
            color: #d8382c;
          }
        }
      }

      .total-price {
        position: relative;
        font-weight: 700;
        font-size: 1.5rem;
      }
    }
  }

  // .btn-custom-dxf {
  //   width: 180px;
  // }

  .b-container-fixed {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 0.75rem 1.275rem;
    background-color: #ffffff;
    box-shadow: 0px 0rem 1.25rem rgba(179, 169, 148, 0.4);
  }
  .s2-parts {
    padding-bottom: 2.5rem;
  }

  .file-mapping {
    position: relative;
    overflow: hidden;
    width: 50vw;
    margin-bottom: 1.5rem;
    padding: 0rem 0.5rem;
    box-shadow: 0px 0px 2.8rem rgba(167, 160, 137, 0.4);
    background-color: #fff;
    border-radius: 5px;
    height: 4vw;

    &::before {
      content: "";
      position: absolute;
      width: 4px;
      height: 100%;
      top: 0;
      left: 0;
      background-color: var(--bs-primary);
    }

    .file-name {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 80%;
    }
  }
  .part-upload-cad {
    width: 100%;
    .ant-upload {
      width: 100%;
    }
    .box-upload {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      background: rgba(0, 0, 0, 0.02);
      border: 3px dashed #d9d9d9;
      border-radius: 8px;
      transition: border-color 0.3s;
      height: 8vw;
      margin-bottom: 0.5rem;
      cursor: pointer;
      
      font-size: 1rem;

      &.cad-uploading {
        * {
          pointer-events: none;
        }
      }
    }
    
  }
}
