::global {
  .delivery-date-inline {
    .ant-form-item {
      margin-bottom: 0 !important;
    }
    .ant-form-item-control-input,
    form,
    .ant-form-item {
      height: 100%;
    }
  }
}
